import { AppConfig } from 'src/app/core/configs/app-config';

export const environment: AppConfig = {
  appUrl: 'https://dev.portal.infrest.de',
  production: false,
  webApiUri: 'https://dev.lap.api.app.portal.infrest.de/',
  webApiManagementUri: 'https://dev.lap.api.portal.infrest.de/',
  localWebApiUris: {
    Formular: 'https://dev.lap.api.app.portal.infrest.de/'
  },
  oauth: {
    identityProvider: 'https://infrestcustomersdev.b2clogin.com/32dfe5f8-48ea-4860-8986-eacd65217e23/v2.0/',
    clientId: '3fa296d9-a81b-4055-9077-0650e2d7d88e',
    b2cTenant: 'infrestcustomersdev',
    flowSignInSignUp: 'b2c_1_sign_in_and_up',
    flowPasswordReset: 'b2c_1_password_reset'
  },
  dataUsageAgreement: 'https://www.infrest.de/footernavigation/datenschutzhinweise/',
  usageAgreement: 'https://www.infrest.de/fileadmin/InfreSt.de/03_Dokumentenablage/NV_Einmalnutzer_ab_01122021.pdf',
  widerrufFile: 'https://dev-static.portal.infrest.de/docs/Widerrufsbelehrung_fuer_Privatkunden.pdf',
  featureFlags: {
    latestVorgaenge: false,
    informationenZurRechnungsrelevanz: true,
    headerMenuProfile: false,
    headerMenuOrganisationSettings: false,
    headerMenuHelp: false,
    sideNavEigeneFilter: true,
    vorgaengeColumnSettings: false,
    vorgaengeSaveView: false,
    werSchrittTablesSortable: false,
    fertigSchrittCosts: true
  }
};
