export type Translations = Record<string, string>;

// Stores the locale id of the loaded resource
let resourceLocale = 'en-US';

// Convert JSON resource file data to Translations
const parseTranslations = (fileData: string): Translations => JSON.parse(fileData).translations;

// Get a resource file URL
const getLanguageUrl = (baseUrl: string, id: string) => `${baseUrl}/${id}`;

const getCookie = (name: string) => {
  const tag = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const values = decodedCookie.split(';');

  for (let value of values) {
    value = value.trim();

    if (value.indexOf(tag) == 0) return value.substring(tag.length, value.length);
  }

  return '';
};

// Load resources from the specific URL
export const getTranslationsByUrl = async (url: string, id: string, setLocaleId = true): Promise<Translations> => {
  const response = await fetch(url);
  if (!response.ok) return null;

  const data = await response.text();
  if (data && setLocaleId) resourceLocale = id;
  return parseTranslations(data);
};

// Load resources matching the give language id. If not found, use the fallback ids.
export const getTranslationsById = async (baseUrl: string, id: string, fallbacks: string[], setLocaleId: boolean): Promise<Translations> => {
  const translations = await getTranslationsByUrl(getLanguageUrl(baseUrl, id), id, setLocaleId);

  // If translations were found return it
  if (translations) return translations;

  // No translations were found. Try the parent language, if any.
  const parts = id.split('-');

  if (parts.length > 1) {
    // Try the parent id. For example de-DE -> de
    let parentId = parts[0];

    for (let i = 1; i < parts.length - 1; i++) parentId = parentId + '-' + parts[i];

    return getTranslationsById(baseUrl, parentId, fallbacks, setLocaleId);
  } else {
    // No more parents. Try the first language in the fallback list, if any.
    if (fallbacks.length > 0) {
      const id = fallbacks.shift();
      return getTranslationsById(baseUrl, id, fallbacks, setLocaleId);
    } else {
      // No more fallback languages. Return null (e.g., no translations found)
      return null;
    }
  }
};

export type TranslateOptions = {
  ignoreCountry?: boolean; // Ignore the country part in the locale if. Default false
  tryAllLanguages?: boolean; // Try all languages in Accept-Language. Default true
  setLocaleId?: boolean; // Set LOCALE_ID to match the loaded resource. Default true
  fallbackId?: string; // Specifies the fallback language/locale id. Default en
  locale?: string; // Specifies the locale to be loaded. Separate multiple locales with a semicolon (;). If null or empty use the browser language. Default null
  cookieName?: string; // Specifies the cookie name that stores the language. If null cookies are ignored. Default null
};

// Load resources matching the browser language(s)
export const getTranslationsEx = (baseUrl: string, options: TranslateOptions = null): Promise<Translations> => {
  const ignoreCountry = options?.ignoreCountry ?? false;
  const tryAllLanguages = options?.tryAllLanguages ?? true;
  const setLocaleId = options?.setLocaleId ?? true;
  const fallbackId = options?.fallbackId ?? 'en';
  let locale = options?.locale ?? null;
  const cookieName = options?.cookieName ?? null;

  if (cookieName) locale = getCookie(cookieName);

  // Get a copy of the browser languages
  let languages = [];

  if (locale) languages = locale.split(';');
  else if (!ignoreCountry) languages = [...navigator.languages];
  else {
    for (const language of navigator.languages) {
      const parts = language.split('-');
      const id = parts[0];

      if (languages.indexOf(id) == -1) languages.push(id);
    }
  }

  // Take the first id from the language list
  const id = languages.shift();

  // If only first should be tried clear the language list
  if (!tryAllLanguages) languages = [];

  // If a fallback has been specified and the language list does not contain it, add the fallback to the list
  if (fallbackId && fallbackId !== id && languages.indexOf(fallbackId) == -1) languages.push(fallbackId);

  // Get the translations
  return getTranslationsById(baseUrl, id, languages, setLocaleId);
};

// Load resources matching the browser language(s)
export function getTranslations(
  baseUrl: string,
  ignoreCountry = false,
  tryAllLanguages = true,
  setLocaleId = true,
  fallbackId = 'en',
  locale: string = null,
  cookieName: string = null
): Promise<Translations> {
  const options: TranslateOptions = {
    ignoreCountry,
    tryAllLanguages,
    setLocaleId,
    fallbackId,
    locale,
    cookieName
  };

  return getTranslationsEx(baseUrl, options);
}
