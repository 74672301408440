import { enableProdMode } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { getTranslations } from './translation-loader';

if (environment.production) {
  enableProdMode();
}

getTranslations(environment.webApiManagementUri + 'api/v1/translation')
  .then(translations => {
    if (translations) loadTranslations(translations);
  })
  .catch(() => {
    console.warn('Could not load translations');
  })
  .finally(() => {
    import('./app/app.module').then(module => {
      platformBrowserDynamic()
        .bootstrapModule(module.AppModule)
        .catch(err => console.error(err));
    });
  });
